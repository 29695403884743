<template>
  <div>
    <CommonStandardList
        slug="Licensee"
        module-name="lm-licensee"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"

export default {
  components: {
    CommonStandardList
  },
  setup() {

    const apiUrls = ref(
        {
          list: "/admin/licensee",
          add: "/admin/licensee"
        }
    )

    const tableHeaders = ref([
      {text: "NAME", value: "name", filter: true},
      {text: "CREATED AT", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
      {name: "name", type: "text", required: true}
    ])

    const searches = []

    return {
      tableHeaders,
      form,
      apiUrls,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
